import React from "react"
import {Link} from 'gatsby'
import { slide as Menu } from 'react-burger-menu'
import window from 'global'

import styles from "./header.module.scss"
import HeaderNav from "./header-nav"
import logoImg from "../../images/logo.svg"
import Notification from './notification'

const style = {
  bmBurgerButton: {
    position: 'absolute',
    width: '36px',
    height: '30px',
    right: '15px',
    top: '30px',
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7',
	width: '3px',
	height: '40px'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    zIndex: '1050'
  },
  bmMenu: {
    background: '#373a47',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    color: '#fff',
    padding: '10px 0',
    textDecoration: 'none',
    border: '0'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

class Header extends React.Component{
	state = {
		notifyStatus : false // true when website is underconstruction 
	}

	handleCloseEvent(){
		this.setState({notifyStatus: false})
	}
	render(){
		const {isCalledByHomePage} = this.props;
		const {notifyStatus} = this.state;
		style['bmBurgerButton'] = {
			position: 'absolute',
			width: '36px',
			height: '30px',
			right: '15px',
			top : notifyStatus ? '60px' : '30px'
		}
		const menus = [{
			'name': 'About',
			'link': '/about',
			'ext_link': false,
			'icon': ''	
		},
		{
			'name': 'Treatments',
			'link': '/treatment-services',
			'ext_link': false,
			'icon': ''	
		},
		{
			'name': 'Our Specialties',
			'link': '/our-specialties',
			'ext_link': false,
			'icon': ''	
		},
		{
			'name': 'Resources',
			'link': '/resources',
			'ext_link': false,
			'icon': ''	
		}]
		return(
			<>
				{
					notifyStatus && 
						<Notification handleClose={()=>this.handleCloseEvent()}/>
				}
				
				<div className={`${styles.headerContainer} ${styles.pt_1rem} ${isCalledByHomePage ? styles.pt_1remIsHomeMobile : ''}`}>
					<HeaderNav isCalledByHomePage={isCalledByHomePage ? true : false} menus={menus}/>
					<div className={`${styles.tAlignCenter} ${styles.mt_1remXS} ${styles.overlayTarget}`}>
						<Link to="/">
							<img src={`${logoImg}`} alt="logo"/>
						</Link>
					</div>
					<div className={`${styles.flexContainerSM} ${styles.hideUnderXS}`}>
						<Link to="/contact" className={`${styles.m_auto} ${styles.btn}`}>Let's get in touch</Link>
					</div>
					<Menu width={`90%`} styles= {style} noOverlay right burgerBarClassName={`${styles.mobileMenus} ${isCalledByHomePage ? styles.isHomeMobile : ''}`}>
				        {
				        	menus.map((item, index)=>{
				        		return (
				        			<Link 
				        				activeClassName={styles.navActive} 
				        				className="menu-item" to={item.link} 
				        				key={`m_${index}`}>
				        					{item.name}
				        			</Link>
				        		)
				        	})
				        }
				        <Link to="/contact" className={styles.contactUs}>Lets get in touch</Link>
				    </Menu>
				</div>
			</>
		);
	}
}


export default Header;
