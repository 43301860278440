import React from 'react'
import {graphql, useStaticQuery} from "gatsby"

const SL = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiSocialLinks {
			    edges {
			      node{
			        link
			        logo {
			          childImageSharp {
			            fluid {
			              src
			            }
			          }
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiSocialLinks.edges.forEach(({node})=>{
		d.push(node);
	})
	return(
		<div className={styles.socialWrapper}>
			{
				d.map((item,index)=>{
					return(
						<div key={`icon_${index}`}>
						{
							item.link && item.link !== '#' &&
							<a href={item.link} target={item.link}><img src={item.logo.childImageSharp.fluid.src} alt="Facebook Logo"/></a>
						}
						</div>
					)
				})
			}
		</div>
	)
}

export default SL