import React from 'react'
import Footer from './footer'

const FooterIndex = () => {
	return (
		<>
			<Footer/>
		</>
	)
}

export default FooterIndex