import React from 'react'

import styles from './notification.module.css'
import Close from '../../images/close.svg'

const N = ({handleClose}) => {
	return (
		<div className={styles.notificationContainer}>
			<div className={styles.messageWrapper}>
				<div className={styles.notify}>
					<h2></h2>
					<p>Website is under construction</p>
				</div>
				<div className={styles.closeNotify}>
					<a href="#" onClick={handleClose}>
						<img src={Close} alt="Revivin wholeness-close"/>
					</a>
				</div>
			</div>
		</div>
	)
}

export default N