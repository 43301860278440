/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import styles from "./layout.module.scss"
import Header from "./header/index"
import Footer from "./footer/index"

const Layout = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
    { props.isCalledByHomePage ?
      <div className={styles.topBackground}>
        <Header siteTitle={data.site.siteMetadata.title} isCalledByHomePage={true}></Header>
        <div className={`${styles.mt_10rem} ${styles.mb_10rem} ${styles.ml_6remSM} ${styles.mr_6remSM} ${styles.ml_1remXS} ${styles.mr_1remXS}`}>
          <h1 className={`${styles.c_white} ${styles.mb_1rem}`}>
            We're on this journey together.
          </h1>
          <p className={`${styles.c_white}`}>
            Bridging the gap between mind and body to offer hope and healing from chronic stress and trauma.
          </p>
        </div>
      </div> :
      <Header siteTitle={data.site.siteMetadata.title} />
    }
        {props.children}
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
