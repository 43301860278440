import React from 'react'

import { navigate } from "gatsby"

class Contact extends React.Component {
	
	handleYes(){
		navigate('/contact');
	}

	render(){
		const {styles} = this.props;
		return (
			<div className={`${styles.upperHalfContainer}`}>
				<h1 className={`${styles.mt_3rem} ${styles.mb_2rem} ${styles.c_white}`}>Do you want to talk?</h1>
				<div>
					<button className={`${styles.mb_4rem} ${styles.btn}`} onClick={()=>this.handleYes()}>Get In Touch</button>
				</div>
			</div>
		)
	}
}

export default Contact;
