import React from 'react'
import Header from './header'

const HeaderIndex = (props) => {
	return(
		<>
		{ props.isCalledByHomePage ?
			<Header isCalledByHomePage={true}></Header> :
			<Header/>
		}
		</>
	)
}

export default HeaderIndex