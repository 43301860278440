import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"
import logoImg2x from '../../images/logo.svg'
import fbLogo from "../../images/social-media-by-flaticon/svg/facebook-white.svg"
import twitterLogo from "../../images/social-media-by-flaticon/svg/twitter-white.svg"
import gPlusLogo from "../../images/social-media-by-flaticon/svg/google-plus-white.svg"
import instagramLogo from "../../images/social-media-by-flaticon/svg/instagram-white.svg"
import rssLogo from "../../images/social-media-by-flaticon/svg/rss-white.svg"

import SocialLinks from './social-links';
import Address from './address'
import Contact from './contact-section'

const Footer = () => {
	return(
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<div className={styles.topWrapper}>
						<div className={styles.item}>
							<Link to="/" className={styles.logo}>
								<img src={`${logoImg2x}`} alt="logo"/>
							</Link>
						</div>
						<div className={styles.item}>
							<SocialLinks styles={styles}/>
						</div>
						<div className={styles.item}>
							<Address styles={styles}/>
						</div>
					</div>
					<div className={styles.bottomWrapper}>

						<div className={styles.line}>
							<span/>
							<p>&copy; revivingWholeness</p>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Footer