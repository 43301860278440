import React from "react"
import { Link } from "gatsby"
import styles from "./header-nav.module.css"
export default (props) => {
	const {menus} = props;
	return(
		<>
			<div className={`${styles.headerNavContainer} ${styles.destopMenus}`}>
				{
					menus.map((item, index)=> {
						return(
							<Link 
								activeClassName={styles.navActive} 
								className={`${styles.mr_1rem} ${styles.navItem} ${props.isCalledByHomePage ? styles.c_white : styles.c_black}`} 
								to={item.link}
								key={`d_${index}`}
								>
									<h4>{item.name}</h4>
							</Link>
						)
					})
				}
			</div>
		</>
	);
};