import React from 'react';
import {graphql, useStaticQuery} from "gatsby"

const AD = ({styles}) => {
	const query = useStaticQuery(graphql`
		query {
			allStrapiAddresses {
			    edges {
			      node {
			        items {
			          value
			          id
			          label
			        }
			      }
			    }
			}
		}
	`)
	let d = [];
	query.allStrapiAddresses.edges.forEach(({node})=>{
		d.push(node)
	})
	d = d[0]
	return (
		<div className={styles.address}>
			{
				d.items.map((item, index)=> {
					return(
						<p key={`ad_${index}`} className={`${styles.addressList} ${item.label==='contact_no' ? styles.contactNo : ''}`} dangerouslySetInnerHTML={{__html: item.value}}/>
					)
				})
			}
			
		</div>
	)
}

export default AD